<template>
  <ClubTemplate :clubId="clubId"
    add="Добавить помещение"
    @clickAdd="clickAddHandler"
  >
    <div class="row">
      <template v-for="(item, index) in roomList">
        <div class="col-12 col-md-12 mb-md" :key="index">
          <router-link :to="{ name: $routeName.CLUB_ROOM_EDIT, params: { clubId: clubId, roomId: item.id }}" class="card-link">
            <CardRoom
              :id="item.id"
              :img="item.img"
              :imgCount="item.imgCount"
              :title="item.title"
              :description="item.description"
              :typeTags="item.typeTags"
              :equipTags="item.equipTags"
              @remove="deleteClubRoomById"
              @edit="editClubRoomById"
            />
          </router-link>
        </div>
      </template>
    </div>
    <b-pagination
      v-if="pageCount > pageLimit"
      v-model="pageCurrent"
      :total-rows="pageCount"
      :per-page="pageLimit"
      @change="paginationChange"

      aria-controls="table"
      align="right"
      last-class="hidden"
      first-class="hidden"
      first-number
      last-number
    ></b-pagination>
  </ClubTemplate>
</template>

<script>
import ClubTemplate from './_component/ClubTemplate.vue';
import CardRoom from '@component/Module/CardRoom.vue';

export default {
  components: {
    ClubTemplate,
    CardRoom
  },
  props: {
    clubId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      roomList: [],
      pageCurrent: 1,
      pageLimit: 5,
      pageCount: null,
    };
  },
  methods: {
    clickAddHandler() {
      this.$router.push({ name: this.$routeName.CLUB_ROOM_ADD, params: { clubId: this.clubId }});
    },
    load(){
      RequestManager.Club.getClubRoomList({ clubId: this.clubId, page: this.pageCurrent, limit: this.pageLimit }).then( (itemList) => {
        this.pageCount = itemList.count;
        this.roomList = itemList.results.map((item) => {
          return {
            id: item.id,
            img: item.courtmedia_set.length && item.courtmedia_set[0].media || null,
            imgs: item.courtmedia_set,
            imgCount: item.courtmedia_set.length,
            title: item.name,
            description: `${item.space} м², ${item.floor_type} • Высота потолка — ${item.ceiling} м • Вместимость — ${item.capacity} человек`,
            typeTags: item.tags.map(i => i.tag),
            equipTags: item.inventory.map(i => i.name),
          };
        });
      });
    },
    paginationChange(val) {
      this.pageCurrent = val;
      this.load();
    },
    // deleteClubRoomById(roomId) {
    //   RequestManager.Club.deleteClubRoomById({ clubId: this.clubId, roomId: roomId, }).then(res => {
    //     this.load();
    //   });
    // },
    deleteClubRoomById(roomId) {
      const options = {title: 'Удалить ?', cancelLabel: 'Нет', okLabel: 'Да'};
      this.$dialogs.confirm('Удалить помещение?', options).then((res) => {
        if(res && res.ok) {
          RequestManager.Club.deleteClubRoomById({ clubId: this.clubId, roomId: roomId, }).then(() => {
            this.$toasts.push({
              message: 'Помещение успешно удалено',
              duration: 4000,
            });
            this.load();
          }, () => {});
        }
      });
    },
    editClubRoomById(roomId) {
      this.$router.push({ name: this.$routeName.CLUB_ROOM_EDIT, params: { clubId: this.clubId, roomId: roomId }});
    }
  },
  mounted() {
    this.load();
  }
};
</script>


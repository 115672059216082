<template>
  <div class="card-room">
    <div class="card-room__body">
      <div class="card-room__img">
        <template v-if="img">
          <img :src="img" alt="photo">
          <p class="card-room__img-overlay">{{ imgCount > 10 ? `${imgCount}+` : imgCount }}</p>
        </template>
        <template v-else>
          <img src="@img/temp/room.png" alt="photo">
          <p class="card-room__img-overlay">0</p>
        </template>
      </div>
      <div class="card-room__content">
        <div class="card-room__title">
          <span class="card-room__title-room">{{ title }}</span>
          <span class="card-room__title-description">{{ description }}</span>
        </div>
        <div class="card-room__tags">
          <div class="card-room__tags-row">
            <div class="card-room__tags-col-title">Виды тренировок:</div>
            <div class="card-room__tags-col-tags">
              <template v-for="(item, index) in typeTags">
                <div class="card-room__tags-ell" :key="index + 'type'">{{ item }}</div>
              </template>
            </div>
          </div>
          <div class="card-room__tags-row">
            <div class="card-room__tags-col-title">Оборудование:</div>
            <div class="card-room__tags-col-tags">
              <template v-for="(item, index) in equipTags">
                <div class="card-room__tags-ell" :key="index + 'eq'">{{ item }}</div>
              </template>
            </div>
          </div>
        </div>
        <div class="card-room__control">
          <!--
           <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 12.05 11.93" v-bind:svg-inline="''" v-bind:class="'card-event__icon'" @click="$emit('edit', id)" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.3 11.93H7.05a.75.75 0 010-1.5h4.25a.75.75 0 010 1.5zM1.26 11.93a.75.75 0 01-.73-.58L0 9.28a1.74 1.74 0 01.37-1.47L5.9.76a2 2 0 013-.23l.93.73a2 2 0 01.49 2.93l-5.57 7.05a1.73 1.73 0 01-1.35.67H1.27zM7.08 1.68l-5.53 7a.24.24 0 000 .2l.35 1.49h1.53a.24.24 0 00.19-.09l5.47-7.02c.28-.36-.07-.7-.23-.83l-.93-.73c-.13-.1-.57-.4-.86 0z"/><path d="M8.65 5.8a.75.75 0 01-.46-.16L5 3.13A.75.75 0 015.91 2l3.2 2.51a.75.75 0 01-.46 1.29z"/></svg>
          -->
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 12.81 13.83" v-bind:svg-inline="''" v-bind:class="'card-event__icon'" @click="$emit('remove', id)" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.38 13.83H3.76A2.22 2.22 0 011.5 11.7C1.29 9.8.93 5.33.93 5.29a.75.75 0 111.5-.12s.36 4.49.57 6.36c.07.67.46.77.8.77h5.19a.75.75 0 00.84-.78c.21-1.86.57-6.31.57-6.36a.76.76 0 01.81-.69.75.75 0 01.69.81s-.37 4.52-.58 6.4A2.24 2.24 0 019 13.81zM12.06 3.83H.75a.75.75 0 010-1.5h11.31a.75.75 0 010 1.5z"/><path d="M9.88 3.83a1.85 1.85 0 01-1.81-1.49l-.17-.81a.2.2 0 00-.09 0H5a.1.1 0 00-.1.08l-.15.76a1.85 1.85 0 01-1.82 1.46.75.75 0 010-1.5.35.35 0 00.34-.33l.16-.81A1.62 1.62 0 015 0h2.81a1.6 1.6 0 011.55 1.19l.18.81a.35.35 0 00.34.28.75.75 0 110 1.5z"/></svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CardRoom",
  props: {
    id: Number,
    img: String,
    imgCount: Number,
    title: String,
    description: String,
    typeTags: Array,
    equipTags: Array
  },
};
</script>

<style lang="scss" scoped>

// card-room
.card-room {
  position: relative;
  padding: 16px 12px;
  background-color: var(--br-block-prime);
  border-radius: 8px;
  .card-room__body {
    display: flex;
    .card-room__img {
      position: relative;
      flex-shrink: 0;
      width: 96px;
      height: 96px;
      border-radius: 4px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &-overlay {
          position: absolute;
          width: 100%;
          bottom: 0px;
          background: rgba(34, 34, 34, 0.6);
          font-size: 25px;
          line-height: 1.4;
          text-align: center;
          color: #CACACA;
      }
    }
    .card-room__content {
      margin-left: 12px;
      .card-room__title {
        margin-bottom: 14px;
        &-room {
          font-size: 17px;
          color: #FFFFFF;
        }
        &-description {
          margin-left: 8px;
          font-size: 14px;
          color: #CACACA;
        }
      }
    }
    .card-event__control {
      display: flex;
      position: absolute;
      top: 8px;
      right: 8px;
      opacity: 0;
      visibility: hidden;
      transition: all .2s ease-in-out;
      .card-event__icon {
        margin-right: 5px;
        cursor: pointer;
        path {
          fill: var(--color-prime);
          transition: all .2s ease-in-out;
        }
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          path {
            fill: var(--color-sub);
          }
        }
      }
    }
  }
  .card-room__tags {
    .card-room__tags-row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .card-room__tags-col-title {
        font-size: 14px;
        line-height: 16px;
        color: #CACACA;
        min-width: 130px;
    }
    .card-room__tags-col-tags {
      display: flex;
      align-items: center;
    }
    .card-room__tags-ell {
      white-space: nowrap;
      display: block;
      margin-right: 4px;
      padding: 6px 8px 4px;
      color: var(--color-prime);
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      border: 1px solid #777971;
      border-radius: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .card-room__control {
    display: flex;
    position: absolute;
    top: 8px;
    right: 8px;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-in-out;
    svg {
      margin-right: 5px;
      cursor: pointer;
      path {
        fill: var(--color-prime);
        transition: all .2s ease-in-out;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        path {
          fill: var(--color-sub);
        }
      }
    }
  }
  &:hover {
    .card-room__control {
      opacity: 1;
      visibility: visible;
    }
  }
}

</style>
